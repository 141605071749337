/**
 * cartridge 接口模块
 */

import axios, {fetchGet, fetchPostQuery} from "../config/http-json";
import axios1 from "axios";
import qs from "qs"

const cartridge = {
  commonProblemList(params) {
    return axios.fetchGet('/cartridge/commonProblemList', params)
  },
  async presetTypes(smokeRecordNumber) {
    let resp = await fetchGet("/cartridge/selectAllProblemList", {smokeRecordNumber})
    resp.data.forEach((item, index) => {
      item.isCanEdit = (item.heatingModeList && item.heatingModeList[0].isCustomModel === 1)
      item.active = false
    })
    return resp.data
  },

  async saveCustomDiyTemperature(id, temperature,smokeRecordNumber) {
    let resp = await fetchPostQuery("/cartridge/saveCustomModel", {id, temperature,smokeRecordNumber})
    return resp.data
  },


  async resetCustomDiyTemperature(id,smokeRecordNumber) {
    let resp = await fetchGet("/cartridge/selectDefaultHeating", {id,smokeRecordNumber})
    return resp.data
  }


};

export default cartridge;
