/**
 * feedback 接口模块
 */

import {fileUpload,} from "../config/http-json";

const feedback = {
  /**
   * 提交问题反馈
   */
  async feedbackAdd(params) {
    let resp = await fileUpload("/feedback/add", params);
    return resp.data;
  },
};

export default feedback;
