import Vue from "vue";
import ShowTips from "./ShowTipsDialog.vue";

const PopupBox = Vue.extend(ShowTips);

ShowTips.install = function(data) {
  let instance = new PopupBox({
    data,
  }).$mount();

  document.body.appendChild(instance.$el);

  Vue.nextTick(() => {
    instance.show = true;
    // show 和弹窗组件里的show对应，用于控制显隐
  });
};

ShowTips.uninstall = function(data) {
  var nodeList = document.body.getElementsByClassName("popupTips");
  nodeList.forEach((element) => {
    element.style.display = "none";
  });
  var nodeList1 = document.body.getElementsByClassName("van-overlay");
  console.log(nodeList1);
  nodeList1.forEach((element) => {
    element.style.display = "none";
  });
  // var nodeList3 = document.body.getElementsByClassName("van-popup");
  // nodeList3.forEach((element) => {
  //   element.style.display = "none";
  // });
  // setTimeout(() => {
  //   var nodeList2 = document.body.getElementsByClassName("van-overlay");
  //   nodeList2.forEach((element) => {
  //     element.style.display = "none";
  //   });
  // }, 1500);
};

export default ShowTips;
