/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:13:24
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-22 17:24:30
 * @Description:
 */
//根据不同的环境更改不同的baseUrl

let baseUrl = null;
export const devModel = "prod"; //  dev / test / prod / cross 本地跨域
if (devModel == "cross") {
  baseUrl = "/dime";
} else if (devModel == "dev") {
  baseUrl = "http://192.168.254.151:8038/dime"; //本地
} else if (devModel == "test") {
  baseUrl = "http://13.57.48.78:8090/supplement/dime"; //美国测试
} else if (devModel == "prod") {
  baseUrl = "https://app.unicoreus.com/supplement/dime"; //美国正式
}
// }
export default baseUrl;
