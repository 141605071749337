<template>
  <div class="footer">
    <div class="row text-center w-100" style="margin-right: 62px">
      <div class="col">
        <div
          style="
            border: 2px solid #ffab00;
            border-radius: 30px;
            background: #ffab00;
          "
          @click="traverse('home')"
        >
          <img
            src="@/assets/icons/icons/home/HOME_WHITE.png"
            style="height: 1.7em"
            class="my-2"
          />
        </div>
      </div>
      <div class="col">
        <!-- <div
          :style="`background-color: ${
            $router.currentRoute.name === 'navigation' ? 'grey' : getAccentColor
          }; border-radius: 30px`"
        > -->
        <div
          style="border-radius: 30px; border: 2px solid #ffab00"
          @click="traverse('navigation')"
        >
          <img
            src="@/assets/icons/icons/navigation_ellipse.png"
            style="height: 0.7em"
            class="my-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      loading: {
        overall: true,
      },
      response: {},
      payload: {},
    };
  },
  created() {
    this.setup();
  },
  methods: {
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      await this.setLoadingState(true);
      // Methods
      await this.setLoadingState(false);
    },
    traverse(action) {
      switch (action) {
        case "home":
          if (this.$router.currentRoute.name === "presets") {
            this.$store.dispatch("changeTemperatureMode");
            this.$router.replace({
              name: "device",
            });
          } else if (this.$router.currentRoute.name !== "device") {
            this.$router.replace({
              name: "device",
            });
          }
          break;
        case "navigation":
          if (this.$router.currentRoute.name !== "navigation") {
            this.$router.replace({
              name: "navigation",
            });
          }
          break;
      }
    },
    /* Misc */
    async setLoadingState(loadingState) {
      this.loading.overall = loadingState;
    },
  },
  computed: {
    ...mapGetters(["getMainColor", "getAccentColor"]),
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .footer {
    max-width: 720px;
  }
}
@media (min-width: 960px) {
  .footer {
    max-width: 900px;
  }
}

.footer {
  height: 10vh;
  position: absolute;
  width: 100%;
  bottom: 0px;
}
</style>
