/**
 * device 接口模块
 */

import axios, {
  fetchGet,
  fetchPost,
  fetchPostQuery,
  fileUpload,
} from "../config/http-json";
import axios1 from "axios";
import qs from "qs";
import store from "../store";

import baseUrl from "../config/env.js";
import bluetoothRepository from "../bluetooth/BluetoothRepository";

const device = {
  async getDeviceNameFromServer(deviceNumber, deviceType) {
    let resp = await fetchGet("/cartridge/findByDeviceNumber", {
      deviceNumber,
      deviceType,
    });
    return resp.data;
  },

  async bindDevice(deviceNumber) {
    let resp = await fetchPostQuery("/device/bindingDevice", {
      deviceNumber,
    });
    return resp.data;
  },

  async getDeviceInfo(deviceNumber) {
    let resp = await fetchGet("/device/findDevice", {
      deviceNumber,
    });
    return resp.data;
  },

  async deviceConnection(
    productionBatch,
    deviceNumber,
    smokeRecordNumber,
    versionNo
  ) {
    let resp = await fetchPostQuery("/device/deviceConnection", {
      productionBatch,
      deviceNumber,
      smokeRecordNumber,
      versionNo,
    });
    return resp.data;
  },

  async synchronizeTime(deviceNumber, deviceType, encryptionType) {
    if (!bluetoothRepository.isConnected()) {
      return;
    }
    let resp = await fetchPostQuery("/device/synchronizeTime", {
      deviceNumber,
      deviceType,
      encryptionType,
    });
    return resp.data;
  },
  async syncSmokeData(params) {
    if (!bluetoothRepository.isConnected()) {
      return;
    }
    let resp = await fetchPost("/cartridge/synchronousData", params);
    return resp.data;
  },

  async ota(hardware) {
    let resp = await fetchGet("/device/selectLatestVersion", {
      hardware,
      otaIdentification: store.state.bluetooth.cartridgeFlag,
    });
    return resp.data;
  },
  async smokeOilType() {
    let resp = await fetchGet("/device/selectTypeList");
    return resp.data;
  },
  async chartLineSmokingTimeRecord(status, oilType, startTime, currentTime) {
    let resp = await fetchGet("/cartridge/selectLineChart", {
      status,
      typeStatus: oilType,
      startTime,
      currentTime,
    });
    return resp.data;
  },

  /**
   * 新版本 我的记录树状图表(带曲线版本)
   */
  async selectChartLineHistogram(
    status,
    oilType,
    startTime,
    pageNum = 1,
    pageSize = 10
  ) {
    let resp = await fetchGet("/cartridge/selectDayWeekMonthHistograms", {
      status,
      typeStatus: oilType,
      startTime,
      pageNum,
      pageSize,
    });
    return resp.data;
  },

  /**
   * 个人中心-我的设备
   */
  async selectMyDevices() {
    let resp = await fetchGet("/device/selectMyDevices");
    return resp.data;
  },

  /**
   * 解绑设备
   */
  async untieDevice(params) {
    let resp = await fetchPostQuery("/device/untieDevice", params);
    return resp.data;
  },

  /**
   * 修改设备信息
   */
  async updateById(params) {
    let resp = await fileUpload("/device/updateById", params);
    return resp.data;
  },
};

export default device;
