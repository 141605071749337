/**
 * api 接口的统一出口
 */

import user from "./user.js";
import userReply from "./userReply.js";
import feedback from "./feedback.js";
import device from "./device.js";
import cartridge from "./cartridge.js";


const api = {
    user,
    userReply,
    feedback,
    device,
    cartridge,
}

//暴露接口 API
export default api;