import Vue from "vue";
import Vuex from "vuex";
import {BluetoothStore} from "./bluetooth/"
import bluetoothRepository from "../bluetooth/BluetoothRepository"
import {routes} from "../router"
import router from "../router"
import cartridge from "../api/cartridge"

Vue.use(Vuex);

export default new Vuex.Store({
  state    : {
    mainColor   : "#000",
    passiveColor: "#d9a845",
    actionColor : "#ffab00",
    loginState  : true, //登录状态
    loginInfo   : null, // 用户信息
    email       : "",
    presetInfo  : null, // 预设信息
  },
  actions  : {
    _setLoginState({commit}, payload) {
      routes.filter(item => (item.meta != undefined &&item.meta.keepAlive != undefined)).forEach(item => item.meta.keepAlive = payload)
      commit("setLoginState", payload);
    },
    _setLoginInfo({commit}, payload) {
      commit("setLoginInfo", payload);
    },
    _setRegisterEmail({commit}, payload) {
      commit("setRegisterEmail", payload);
    },
    async _setPresetInfo(context, payload) {
      context.commit("setPresetInfo", payload)
    },
    async tokenExpired({commit}) {
      // 登录验证
      //授权令牌无效，请重新登录
      bluetoothRepository.disconnect()
      commit("setLoginState", false);
      commit("setLoginInfo", null);
      commit("setRegisterEmail", '');
      commit("setPresetInfo", null)
      localStorage.clear()
      router.replace({
        name: "login" //从哪个页面跳转
      });
    }
  },
  mutations: {
    setLoginState(state, payload) {
      state.loginState = payload;
    },
    setLoginInfo(state, payload) {
      state.loginInfo = payload;
    },
    setRegisterEmail(state, payload) {
      state.email = payload;
    },
    setPresetInfo(state, payload) {
      state.presetInfo = payload;
    }
  },
  getters  : {
    getMainColor(state) {
      return state.mainColor;
    },
    getAccentColor(state) {
      return state.passiveColor;
    },
    getActionColor(state) {
      return state.actionColor;
    },
    getLoginState(state) {
      return state.loginState;
    },
    getLoginInfo(state) {
      return state.loginInfo;
    },
    getRegisterEmail(state) {
      return state.email;
    },
    getPresetInfo(state) {
      return state.presetInfo;
    },
  },
  modules  : {
    bluetooth: BluetoothStore
  },
});
