<template>
  <div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="onCancel"
        :formatter="formatter"
        :columns-order="['month', 'day', 'year']"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "VerifyAgeDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(2000, 6, 10),
    };
  },
  methods: {
    // 点击取消按钮时触发的事件
    onCancel() {
      this.$emit("child-cancel", false);
    },
    // 点击完成按钮时触发的事件
    onConfirm(value) {
      console.log(value);
      if (this.satisfy(value)) {
        // Toast.fail("Restricted to use over 21 years old.");
        this.$showTips({
          msg: "Restricted to use over 21 years old.",
          duration: 3000,
        });
        return;
      } else {
        this.$emit("child-cancel", false);
        this.$emit("child-confirm", value);
      }
    },
    /*
     * 判断是否满足21岁
     * time : 毫秒级时间戳，用户选中日期的时间戳
     * */
    satisfy(time) {
      var nowDate = new Date(); // 当前时间
      var mouth = nowDate.getMonth() + 1;
      var day = nowDate.getDate();
      var year = nowDate.getFullYear() - 21;
      if (mouth === 2 && day >= 28) {
        day = this.isOrdinaryYear(year) ? 28 : 29;
      }
      // 判断安卓&&IOS
      var oldDate;
      if (this.isIOS()) {
        oldDate = new Date(year + "/" + mouth + "/" + day).getTime();
      } else {
        oldDate = new Date(year + "-" + mouth + "-" + day).getTime();
      }
      return oldDate < time ? true : false; // true 表示不满21
    },
    /*
     * 判断平年闰年
     * y:年份数字，四位
     * */
    isOrdinaryYear(y) {
      if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) {
        return false;
      }
      return true;
    },
    isIOS() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isiOS;
    },
    // 日期格式化
    formatter(type, val) {
      if (type === "year") {
      } else if (type === "month") {
        switch (val) {
          case "01":
            return "Jan";
          case "02":
            return "Feb";
          case "03":
            return "Mar";
          case "04":
            return "Apr";
          case "05":
            return "May";
          case "06":
            return "Jun";
          case "07":
            return "Jul";
          case "08":
            return "Aug";
          case "09":
            return "Sept";
          case "10":
            return "Oct";
          case "11":
            return "Nov";
          case "12":
            return "Dec";
          default:
            break;
        }
      }
      return val;
    },
  },
};
</script>