<template>
  <v-app
    style="
      background: linear-gradient(to top right, #000000, #303030);
      overflow: hidden;
    "
  >
    <v-main>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件，比如 Home！ -->
        </router-view>
      </keep-alive>

      <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
      </router-view>
    </v-main>
    <audio src="" id="eventAudio"></audio>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style lang="less">
// img {
//   pointer-events: none !important;
// }
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input {
  -webkit-user-select: auto !important;
}
textarea {
  -webkit-user-select: auto !important;
}

/* 点击搜索框获取焦点 placeholder消失-开始 */

/* WebKit browsers */

input:focus::-webkit-input-placeholder {
  color: transparent;

  /* transparent是全透明黑色(black)的速记法，即一个类似rgba(0,0,0,0)这样的值 */
}

/* Mozilla Firefox 4 to 18 */

input:focus:-moz-placeholder {
  color: transparent;
}

/* Mozilla Firefox 19+ */

input:focus::-moz-placeholder {
  color: transparent;
}

/* Internet Explorer 10+ */

input:focus:-ms-input-placeholder {
  color: transparent;
}
/* 点击搜索框获取焦点 placeholder消失-结束 */

/* 点击搜索框获取焦点 placeholder消失-开始 */

/* WebKit browsers */

textarea:focus::-webkit-input-placeholder {
  color: transparent !important;

  /* transparent是全透明黑色(black)的速记法，即一个类似rgba(0,0,0,0)这样的值 */
}

/* Mozilla Firefox 4 to 18 */

textarea:focus:-moz-placeholder {
  color: transparent !important;
}

/* Mozilla Firefox 19+ */

textarea:focus::-moz-placeholder {
  color: transparent !important;
}

/* Internet Explorer 10+ */

textarea:focus:-ms-input-placeholder {
  color: transparent !important;
}
/* 点击搜索框获取焦点 placeholder消失-结束 */
</style>
