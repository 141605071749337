<template>
  <div style="height: 5vh" class="mb-3">
    <div class="row">
      <div class="col text-right">
        <img
          @click="traverse('settings')"
          src="@/assets/icons/icons/gear.png"
          style="height: 2.5em; margin-top: 0.7em"
          v-if="showIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "settings_gear",
  props: ["showIcon"],
  methods: {
    traverse(action) {
      switch (action) {
        case "settings":
          this.$router.push({
            name: "settings",
          });
          break;
      }
    },
  },
};
</script>

<style></style>
