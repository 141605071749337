<template>
  <div>
    <van-popup
      round
      v-model="show"
      class="update-device-popup"
      :close-on-click-overlay="false"
    >
      <div class="popup-title">Modify device name</div>
      <div class="popup-content">
        <div class="content-row">
          <div class="content-row-title">Old Name：</div>
          <div style="padding: 0 10px">
            {{ device != null ? device.deviceName : "" }}
          </div>
        </div>
        <div class="content-row">
          <div class="content-row-title">New Name：</div>
          <input
            ref="newName"
            class="content-row-input"
            type="text"
            maxlength="20"
            v-model.trim="newName"
          />
        </div>
        <div></div>
      </div>
      <div class="popup-bottom">
        <div class="popup-button-cancel text-uppercase" @click="onCloseDialog">
          cancel
        </div>
        <div
          class="popup-button-confirm text-uppercase"
          @click="onClickConfirm"
        >
          confirm
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "UpdateDeviceDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newName: "",
    };
  },
  methods: {
    onCloseDialog() {
      this.$emit("updialog-close", false);
    },
    onClickConfirm() {
      if (this.newName.trim().length == 0) {
        // Toast("Please enter a new device name.");
        this.$showTips({
          msg: "Please enter a new device name.",
          duration: 3000,
          showType: "info",
        });
        this.newName = this.newName.trim();
        this.$refs.newName.focus();
        return;
      }

      let params = new FormData();
      params.append("deviceNumber", this.device.deviceNumber);
      params.append("deviceName", this.newName);
      this.showLoading("Modifying...");
      this.$api.device
        .updateById(params)
        .then((res) => {
          if (res.code === 200) {
            this.hideLoading();
            // Toast.success(res.message);
            this.$showTips({
              msg: res.message,
              duration: 3000,
              showType: "info",
            });
            this.$emit("updialog-close", false);
            this.$emit("updialog-confirm", this.newName);
            this.newName = "";
          }
        })
        .catch(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
<style lang="less" scoped>
.update-device-popup {
  height: 220px;
  width: 80%;
  background: #222222;
  z-index: 2008;
}
.popup-title {
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding: 10px;
}
.popup-content {
  color: #ffffff;
  font-size: 16px;
  padding: 20px 10px;

  .content-row {
    width: 100%;
    display: flex;
    margin-top: 5px;
    align-items: center;

    .content-row-title {
      width: 38%;
      text-align: right;
      white-space: nowrap;
    }

    .content-row-input {
      border: 1px solid #d9a845;
      width: 60%;
      border-radius: 25px;
      padding: 0 10px;
      height: 30px;
    }
  }
}
.popup-bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  font-size: 20px;

  .popup-button-cancel {
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    width: 50%;
    text-align: center;
    color: #ffffff;
    background: #222222;
  }

  .popup-button-confirm {
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    width: 50%;
    text-align: center;
    color: #ffffff;
    background: #fec44f;
    border-radius: 16px;
  }
}
</style>