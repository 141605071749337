<template>
  <van-popup
    v-model="show"
    round
    class="popupTips"
    :close-on-click-overlay="isClose"
  >
    <span class="title">{{ msg }}</span>
    <img
      v-if="showType == 'fail'"
      src="@/assets/icons/exclamation.png"
      width="160vw"
      height="160vw"
    />
    <img
      id="loading"
      v-if="showType == 'loading'"
      :src="imgSrc"
      width="50vw"
      height="50vw"
    />
  </van-popup>
</template>

<script>
export default {
  name: "ShowTipsDialog",
  props: {},
  data() {
    return {
      msg: "",
      show: false,
      duration: 3000,
      isClose: true, // 是否可以点击遮罩层关闭
      showType: "fail", // info:无感叹号  fail:带感叹号
      imgSrc: require("@/assets/icons/loading.gif") + "?time=" + Math.random(),
    };
  },
  methods: {},
  watch: {
    show(newValue, oldValue) {
      if (newValue && this.duration != 0) {
        setTimeout(() => {
          this.show = false;
          var nodeList = document.body.getElementsByClassName("popupTips");
          nodeList.forEach((element) => {
            element.style.display = "none";
          });
          var nodeList1 = document.body.getElementsByClassName("van-overlay");
          nodeList1.forEach((element) => {
            element.style.display = "none";
          });
          // setTimeout(() => {
          //   var nodeList2 = document.body.getElementsByClassName("van-overlay");
          //   nodeList2.forEach((element) => {
          //     element.style.display = "none";
          //   });
          // }, 1000);
        }, this.duration);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.popupTips {
  display: flex;
  flex-direction: column;
  width: 80vw;
  background-color: #222222;
  padding: 16px 24px;
  align-items: center;
  justify-content: center;

  .title {
    color: #f6f6f6;
    font-weight: bold;
    font-size: 5vw !important;
    text-align: center;
    word-break: keep-all;
  }
  img {
    margin-top: 4vw;
  }
}
</style>