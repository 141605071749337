
export class SyncData {
    year     = 0
    month    = 0
    day      = 0
    timeSlot = {}

    static create(dataView) {
        const syncData = new SyncData()

        syncData.year  = dataView.getUint16(0, true)
        syncData.month = dataView.getUint8(2)
        syncData.day   = dataView.getUint8(3)
        for (let i = 0; i < 24; i++) {
            syncData.timeSlot[i] = dataView.getUint16(4 + i * 2, true)
        }
        return syncData
    }
}

export class DiyTemperatureAndTime {
    diyTime           = 6
    secondTemperature = []

    constructor(dataView) {
        if (dataView instanceof DataView) {
            if (dataView.byteLength === 21) {
                for (let i = 0; i < 10; i++) {
                    this.secondTemperature.push({
                        x: (i + 1),
                        y: new DataView(dataView.buffer.slice(i * 2, i * 2 + 2)).getUint16(0, true)
                    })
                }
                this.diyTime = dataView.getUint8(20)
            }
        }
    }

    generateCommand() {
        const dataView = new DataView(Buffer.alloc(21).buffer)

        for (let i = 0; i < 10; i++) {
            if (i <= this.secondTemperature.length) {
                dataView.setUint16(i * 2, this.secondTemperature[i], true)
            }
        }
        dataView.setUint8(20, this.diyTime)
        return dataView
    }
}


export class PreheatTemperatureAndTime {
    preheatTemperature = 0
    preheatSecond      = 0

    constructor(dataView) {
        if (dataView instanceof DataView) {
            if (dataView.byteLength === 3) {
                this.preheatTemperature = dataView.getUint16(0, true)
                this.preheatSecond      = dataView.getUint8(2)
            }
        }
    }


    generateCommand() {
        const dataView = new DataView(Buffer.alloc(3).buffer)
        dataView.setUint16(0, this.preheatTemperature, true)
        dataView.setUint8(2, this.preheatSecond)
        return dataView
    }
}