import axios from "axios";
import router from "../router/index";
import store from "../store/index.js";
import baseUrl from "../config/env.js";
import { Toast } from "vant";
import ShowTips from "@/components/dialog/index.js";

// 封装 axios
axios.defaults.headers["Content-Type"] = "application/json";

// 创建axios 实例
const service = axios.create({
  baseURL: baseUrl,
  timeout: 130000, // 请求超时
  withCredentials: false,
});

/**
 * 封装 请求
 * 请求拦截、 响应拦截，异常统一处理
 */

let showTips = ShowTips.install;
let hideTips = ShowTips.uninstall;

const ASCRIPTION = "ASCRIPTION";
const TIME_ZONE = "timeZone";
const SOURCE = "source";
//http request 拦截器
service.interceptors.request.use(
  (config) => {
    // 获取token
    const token = window.localStorage.getItem("front-token");
    config.headers["front-token"] = token;

    let timezone = new Date().getTimezoneOffset() / -60;
    if (timezone > 0) {
      timezone = `+${timezone}:00`;
    } else {
      timezone = `${timezone}:00`;
    }
    config.headers[TIME_ZONE] = timezone;
    config.headers[SOURCE] = navigator.userAgent.includes("AppleWebKit")
      ? "2"
      : "1";
    config.headers[ASCRIPTION] = "9";

    // config.transformRequest = [
    //   function(data) {
    //     data = JSON.stringify(data);
    //     return data;
    //   },
    // ];
    window.console.log(config);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//http response 拦截器
service.interceptors.response.use(
  (response) => {
    const code = response.data.code;
    if (code == 401) {
      store.dispatch("tokenExpired");
    } else if (code === 500) {
      // this.$bvToast.toast(response.data.msg);
    } else if (code == 404) {
      // 404
    } else if (code == 200) {
      return response;
    }

    hideTips();
    showTips({
      msg: response.data.message,
      duration: 5000,
      isClose: false,
    });

    // Toast.fail({
    //   duration: 2000,
    //   message: response.data.message,
    //   closeOnClick: false,
    //   closeOnClickOverlay: false,
    // });
    return Promise.reject(response.data.message);
  },
  (error) => {
    hideTips();
    this.showTips({
      msg: error.message,
      duration: 2000,
      isClose: false,
    });

    // Toast.fail({
    //   duration: 2000,
    //   message: error.message,
    //   closeOnClick: false,
    //   closeOnClickOverlay: false,
    // });
    return Promise.reject(error);
  }
);

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function fetchGet(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "get",
      params: params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function fetchPost(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "post",
      data: params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function fetchPostQuery(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "post",
      data: null,
      params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
  console.log(params);
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "post",
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default { fetchGet, fetchPost, fileUpload };
