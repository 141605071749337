import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Components
import SettingsGear from "@/components/Global/SettingsGear";
import Footer from "@/components/Global/Footer";
import UpdateDevice from "@/components/dialog/UpdateDeviceDialog";
import audio from "@/assets/ui/buttom.mp3";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import enUS from "vant/es/locale/lang/en-US";
import ShowTips from "@/components/dialog/index.js";

import api from "./api/index";
import {
  Image,
  Field,
  Button,
  Toast,
  Form,
  Col,
  Row,
  Popup,
  Uploader,
  RadioGroup,
  Radio,
  Cascader,
  Checkbox,
  DatetimePicker,
  Step,
  Steps,
  PasswordInput,
  NumberKeyboard,
  Tab,
  Tabs,
  Popover,
  PullRefresh,
  Pagination,
  List,
  Locale,
  Swipe,
  SwipeItem,
} from "vant";

Vue.use(Popup);
Vue.use(Toast);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(PullRefresh);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(List);
Vue.use(Cascader);
Vue.use(DatetimePicker);
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(VueAwesomeSwiper);

Toast.setDefaultOptions({ overlay: true });
Toast.setDefaultOptions({ duration: 3000 });
Toast.setDefaultOptions({ closeOnClick: true });
Toast.setDefaultOptions({ closeOnClickOverlay: true });

Locale.use("en-US", enUS);

Vue.component("SettingsGear", SettingsGear);
Vue.component("Footer", Footer);
Vue.component("UpdateDevice", UpdateDevice);

Vue.prototype.$showTips = ShowTips.install;
Vue.prototype.$hideTips = ShowTips.uninstall;

Vue.config.productionTip = false;

Vue.prototype.playAudio = () => {
  let buttonAudio = document.getElementById("eventAudio");
  buttonAudio.setAttribute("src", audio);
  buttonAudio.play();
};

//暴露api接口
Vue.prototype.$api = api;

Vue.prototype.showLoading = function(msg) {
  // Toast.loading({
  //   duration: 0, // 持续展示 toast
  //   forbidClick: true,
  //   message: msg,
  // });
  this.$showTips({
    msg: msg == "" ? "loading..." : msg,
    duration: 0,
    showType: "loading",
    isClose: false,
  });
};
Vue.prototype.hideLoading = function() {
  // Toast.clear();
  this.$hideTips();
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
