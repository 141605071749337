import Vue from 'vue'
import VueRouter from 'vue-router'
import CountryState from '@/components/dialog/CountryStateDialog'
import VerifyAge from '@/components/dialog/VerifyAgeDialog'
import UpdateDevice from '@/components/dialog/UpdateDeviceDialog'
import ShowTipsDialog from '@/components/dialog/ShowTipsDialog'

import Store from '@/store/index/'
import log from '@/util/log/'

Vue.use(VueRouter)

export const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/Login.vue'),
	},
	{
		path: '/verify_code',
		name: 'verify_code',
		component: () => import('@/views/register/VerifyCode.vue'),
	},
	{
		path: '/complete_profile',
		name: 'complete_profile',
		component: () => import('@/views/register/CompleteProfile.vue'),
		meta: {
			keepAlive: true,
		},
	},
	// Pair
	{
		path: '/pair/main',
		name: 'pair_main',
		component: () => import('@/views/pair/Pair_Landing.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	// Landing
	{
		path: '/device',
		name: 'device',
		component: () => import('@/views/Device.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
			keepAlive: true,
		},
	},
	// Landing
	{
		path: '/webview',
		name: 'webview',
		component: () => import('@/views/WebView.vue'),
	},
	// deviceList
	{
		path: '/deviceList',
		name: 'deviceList',
		component: () => import('@/views/DeviceList.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	// Views
	{
		path: '/navigation',
		name: 'navigation',
		component: () => import('@/views/Navigation.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/presets',
		name: 'presets',
		component: () => import('@/views/Presets.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
			keepAlive: true,
		},
	},
	{
		path: '/settings',
		name: 'settings',
		component: () => import('@/views/Settings.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/faq',
		name: 'faq',
		component: () => import('@/views/Faq.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/faqDetails',
		name: 'faqDetails',
		component: () => import('@/views/FaqDetails.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/support',
		name: 'support',
		component: () => import('@/views/Support.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/editPresets',
		name: 'editPresets',
		component: () => import('@/views/EditPresets.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/myStats',
		name: 'myStats',
		component: () => import('@/views/record/MyRecord.vue'),
		meta: {
			auth: true, //添加字段判断该页面是否需要登录
		},
	},
	{
		path: '/countryState',
		name: 'countryState',
		component: CountryState,
	},
	{
		path: '/verifyAge',
		name: 'verifyAge',
		component: VerifyAge,
	},
	{
		path: '/updateDevice',
		name: 'updateDevice',
		component: UpdateDevice,
	},
	{
		path: '/ShowTipsDialog',
		name: 'ShowTipsDialog',
		component: ShowTipsDialog,
	},
	{
		path: '/getCode',
		name: 'getCode',
		component: () => import('@/views/forgot/GetCode.vue'),
	},
	{
		path: '/editPassword',
		name: 'editPassword',
		component: () => import('@/views/forgot/EditPassword.vue'),
	},
	{
		path: '*',
		redirect: { name: 'login' },
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	// to要跳转到的路径
	// from从哪个路径来
	// next往下执行的回调
	// 在localStorage中获取token

	log('读取数据')
	let token = window.localStorage.getItem('front-token')
	let userInfo = window.localStorage.getItem('userInfo')

	// 判断该页面是否需要登录
	if (to.meta.auth) {
		// 如果token存在直接跳转
		if (token) {
			Store.commit('setLoginInfo', userInfo)
			log('准备陆游')
			next()
		} else {
			// 否则跳转到login登录页面
			next({
				path: '/login',
				// 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
				query: {
					redirect: to.fullPath,
				},
			})
		}
	} else {
		// 如果不需要登录，则直接跳转到对应页面
		next()
	}
})

export default router
