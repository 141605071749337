/**
 * user 接口模块
 */

import axios, {
  fetchGet,
  fetchPost,
  fetchPostQuery,
} from "../config/http-json";
import axios1 from "axios";
import qs from "qs";
import baseUrl from "../config/env";

const service = axios1.create({
  baseURL: baseUrl,
  timeout: 130000, // 请求超时
  withCredentials: false,
});

const user = {
  //登录 Post
  login(params) {
    return service
      .post("/user/doLogin", qs.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((x) => x.data);
  },
  // 用户注册
  addUser(params) {
    return service.post("/user/addUser", qs.stringify(params), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  // 发送邮箱进行注册
  sendEmailToRegister(params) {
    return service.post("/user/sendEmailToRegister", qs.stringify(params), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  // 发送邮箱找回密码
  sendEmail(params) {
    return service.post("/user/sendEmail", qs.stringify(params), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },

  // 修改用户密码
  retrievePassword(params) {
    return service.post("/user/retrievePassword", qs.stringify(params), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },

  // 验证验证码是否正确
  verificationCode(params) {
    return service.get(
      "/user/verificationCode?code=" +
        params.code +
        "&email=" +
        params.email +
        "",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  // 查询用户信息
  findUser() {
    return service.get("/user/findUser", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "front-token": window.localStorage.getItem("front-token"),
      },
    });
  },
  // 退出登录
  async logout() {
    let resp = await fetchPost("/user/logout");
    return resp;
  },
  // 查询所有的国家和洲下拉框
  async selectAllCountry() {
    let resp = await fetchGet("/user/selectAllCountry");
    return resp.data;
  },
};

export default user;
