<template>
  <div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-cascader
        v-model="cascaderValue"
        title="Please select your country and state"
        :options="options"
        :field-names="fieldNames"
        active-color="#ffab00"
        @close="onClose"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "CountryStateDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldValue: "",
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "name",
        children: "states",
      },
      options: [],
    };
  },
  created() {
    this.$api.user
      .selectAllCountry()
      .then((data) => {
        this.options = data;
      })
      .catch((data) => {
        this.options = [
          {
            name: "Server Error",
            code: "Server Error",
            states: [
              {
                name: "Server Error",
                code: "Server Error",
              },
            ],
          },
        ];
      });
  },
  methods: {
    // 关闭弹窗
    onClose() {
      this.$emit("child-close", false);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      //this.show = false;
      this.$emit("child-close", false);
      this.$emit("child-finish", selectedOptions[0], selectedOptions[1]);
      this.fieldValue = selectedOptions.map((option) => option.name).join("/");
    },
  },
};
</script> 